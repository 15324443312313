@import "src/styles/_variables-mixins.scss";
.accessExplore {
  width: 100%;

  margin-top: 140px;
  margin-bottom: 140px;

  background-color: var(--mobility-color-orange-600);

  :global(.container) {
    display: flex;
    align-items: center;
  }

  &__image {
    position: relative;
    display: flex;
    flex-shrink: 0;

    width: 58%;
    height: 95vh;
  }

  &__content {
    display: flex;
    flex-direction: column;

    padding: 48px;
  }

  &__actions {
    display: flex;
    align-items: center;
    gap: 24px;

    margin-top: 48px;
  }

  @include mediaQuery($md) {
    :global(.container) {
      display: flex;
      align-items: center;
      flex-direction: column;

      padding: 0;
    }

    &__image {
      width: 100%;
      height: 480px;

      object-fit: cover;
    }

    &__content {
      display: flex;
      flex-direction: column;

      padding: 48px;
    }

    &__actions {
      flex-direction: column;

      width: 100%;

      li {
        width: 100%;
      }

      :global(.button) {
        width: 100%;
      }
    }
  }
}
