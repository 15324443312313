@import "src/styles/_variables-mixins.scss";
.sidebar {
  display: flex;
  flex-direction: column;
  flex-shrink: 0;

  padding: 30px;
  width: 100%;
  max-width: 272px;

  @include mediaQuery($md) {
    display: none;
    position: absolute;
    z-index: 140;
    // inset: 0;
    // overflow-y: auto;
    top: 0;
    left: 0;
    right: 0;

    max-width: 100%;

    border-radius: 0 !important;

    &--open {
      display: flex;
    }

    &--desktop-only {
      display: none;
    }
  }
}

.mobileHeader {
  display: none;
  align-items: center;
  justify-content: space-between;

  width: 100%;
  margin-top: -15px;

  :global(.button) {
    height: 45px !important;

    padding: 0;

    &:hover {
      background-color: transparent !important;
      border-color: transparent !important;
      color: var(--mobility-color-dark-950) !important;
    }
  }

  h4 {
    font-size: 20px;
    font-weight: 500;
    line-height: 25px;
    text-align: center;
  }

  @include mediaQuery($md) {
    display: flex;
  }
}

.filter {
  display: flex;
  flex-direction: column;

  width: 100%;
  margin-bottom: 26px;

  &__header {
    display: flex;
    justify-content: space-between;

    padding: 2px 0;
    margin-bottom: 16px;
    width: 100%;

    cursor: pointer;
  }

  &__title {
    color: var(--mobility-color-dark-950);
    font-size: calcFontSize(14px);
    font-weight: 700;
  }

  @include mediaQuery($md) {
    &--desktop-only {
      display: none;
    }
  }
}

.list {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;

  width: 100%;

  li {
    display: flex;
    align-items: center;
    justify-content: flex-start;
  }

  &--col {
    flex-direction: column;
    flex-wrap: unset;

    li {
      width: 100%;
    }
  }

  &--two-items {
    li {
      width: calc(50% - 8px);
    }
  }

  &__item {
    display: flex;
    align-items: center;
    justify-content: flex-start;

    width: 100%;
    min-width: 36px;
    padding: 8px 10px;

    font-family: var(--font-outfit);
    font-size: calcFontSize(14px);
    font-weight: 400;
    text-align: left;
    color: var(--mobility-color-dark-800);

    border-radius: 8px;
    border: 1px solid var(--mobility-color-dark-400);
    background-color: transparent;
    cursor: pointer;

    &--center {
      justify-content: center;
    }

    &:hover {
      background-color: var(--mobility-color-dark-100);
      color: var(--mobility-color-dark-700);
    }

    &--active {
      background-color: var(--mobility-color-orange-600);
      border-color: var(--mobility-color-orange-600);
      color: var(--mobility-color-light-50);

      svg > * {
        stroke: var(--mobility-color-light-50);
      }

      &:hover {
        background-color: var(--mobility-color-orange-500);
        color: var(--mobility-color-light-50);
      }
    }
    &--active-brand {
      background-color: var(--mobility-color-light-100);
      border: 3px solid #000;

      &:hover {
        background-color: var(--mobility-color-dark-50);
      }
    }
    &--active-secondary {
      background-color: var(--mobility-color-purple-200);
      border-color: var(--mobility-color-purple-200);
      color: var(--mobility-color-purple-600);

      &:hover {
        background-color: var(--mobility-color-purple-300);
        color: var(--mobility-color-purple-600);
      }
    }

    img {
      max-height: 18px;
    }
  }

  &__icon {
    position: relative;
    display: block;

    width: 16px;
    height: 16px;
    margin-right: 4px;

    :global(.icon) {
      height: 100%;
    }
  }
}

.mapsContainer {
  position: relative;
  width: 115%;
  height: 100%;
  display: flex;
  justify-content: center;
  cursor: pointer;
  align-self: center;
  padding-bottom: 1rem;
  transform: translateY(-10%);

  @include mediaQuery($md) {
    transform: translateY(0);
    width: 100%;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
}

.mapsContainerText {
  position: absolute;
  width: 6rem;
  height: 2rem;
  display: flex;
  justify-content: center;
  align-self: center;
  align-items: center;
  background-color: #f0f0f0;
  border: 0.3rem solid #fff;
  border-radius: 1rem;
  text-align: center;
  font-size: 0.8rem;

  @include mediaQuery($md) {
    width: 8rem;
    height: 2.7rem;
  }
}

#maps {
  cursor: pointer;
  position: absolute;
  max-width: 100%;
  max-height: 100%;
  object-fit: cover;
}

.sidebar--disabled {
  opacity: 0.5;
  pointer-events: none;
}
