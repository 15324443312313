@import "src/styles/_variables-mixins.scss";
.switch {
  display: flex;
  align-items: center;

  width: 100%;

  &__label {
    padding-left: 10px;

    font-size: calcFontSize(15px);
    font-weight: 500;
    color: var(--mobility-color-dark-800);
  }
}

.switchRoot {
  position: relative;
  display: flex;
  align-items: center;

  width: 42px;
  height: 25px;

  background-color: transparent;
  border-radius: 20px;
  border: 1px solid var(--mobility-color-light-500);
  -webkit-tap-highlight-color: white;

  &:focus {
  }

  &[data-state='checked'] {
    background-color: var(--mobility-color-light-300);
  }
}

.switchThumb {
  display: block;
  width: 18px;
  height: 18px;
  background-color: var(--mobility-color-light-500);
  border-radius: 9999px;
  transition: transform 100ms;
  transform: translateX(2px);
  will-change: transform;

  &[data-state='checked'] {
    transform: translateX(19px);
  }
}
