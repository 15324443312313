@import "src/styles/_variables-mixins.scss";
.priceSlider {
  position: relative;
  display: flex;
  align-items: center;
  user-select: none;
  touch-action: none;
  width: 200px;
  height: 20px;

  &__track {
    background-color: var(--mobility-color-base-gray);
    position: relative;
    flex-grow: 1;
    border-radius: 9999px;
    height: 9px;
  }

  &__range {
    position: absolute;
    background-color: var(--mobility-color-orange-700);
    border-radius: 9999px;
    height: 100%;
  }

  &__thumb {
    display: block;

    width: 20px;
    height: 20px;

    border: 3px solid var(--mobility-color-orange-700);
    background-color: var(--mobility-color-light-50);
    box-shadow: 0 2px 6px rbga(0, 0, 0, 0.35);
    border-radius: 50%;
    cursor: pointer;

    &:hover {
      border-color: var(--mobility-color-orange-800);
    }
    &:focus {
      outline: none;
      box-shadow: 0 0 0 1px var(--mobility-color-orange-800);
    }
  }
}

.prices {
  display: flex;
  align-items: center;
  justify-content: space-between;

  width: 100%;

  span {
    font-size: calcFontSize(14px);
    font-weight: 400;
    color: var(--mobility-color-dark-700);
  }
}
