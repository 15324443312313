@import "src/styles/_variables-mixins.scss";
.pagination {
  display: flex;
  align-items: center;
  justify-content: space-between;

  width: 100%;

  &__dots {
    display: flex;
    align-items: center;
    gap: 16px;
  }
  &__dot {
    display: block;

    width: 16px;
    height: 16px;

    background-color: var(--mobility-color-light-50);
    border-radius: 50%;
    border: 4px solid var(--mobility-color-dark-950);

    text-indent: -9999px;
    color: transparent;
    font-size: 2px;
    cursor: pointer;

    &:hover,
    &--active {
      background-color: var(--mobility-color-orange-700);
      border-color: var(--mobility-color-orange-700);
    }
  }

  &__actions {
    display: flex;
    align-items: center;
    gap: 24px;

    button {
      display: flex;
      align-items: center;
      justify-content: center;

      padding: 5px;

      background: transparent;
      border: 0;
      cursor: pointer;

      span {
        color: var(--mobility-color-dark-950);
        font-size: 28px;
      }

      &:hover {
        span {
          color: var(--mobility-color-orange-700);
        }
      }

      &:last-child {
        padding-right: 0;
      }
    }
  }
}
