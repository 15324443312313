@import "src/styles/_variables-mixins.scss";
.recomendationsList {
  margin-bottom: 140px;

  :global(.section-header) {
    :global(.icon) {
      margin-left: 16px;
    }
  }

  &__list {
    display: flex;
    gap: 24px;

    width: 100%;
  }

  @include mediaQuery($md) {
    :global(.section-header) {
      flex-direction: column;
      align-items: flex-start;

      :global(.button) {
        margin-top: 24px;
      }

      & > * {
        max-width: 100% !important;
      }
    }

    &__list {
      flex-direction: column;
      & > * {
        width: 100% !important;
        max-width: 100% !important;
      }
    }
  }
}
