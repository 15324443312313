@import "src/styles/_variables-mixins.scss";
.card {
  display: flex;

  background-color: var(--mobility-color-light-50);

  border-radius: 24px;

  /* Shadow */
  box-shadow: 0px 4px 30px 0px rgba(76, 76, 76, 0.15);
}
